import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

// Image
// import DefaultImg from "../images/default.avif";
import QRCode from "../images/IG-QR.webp";

export default function Instagram() {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "10px",
  };

  const fetchInstagramPictures = async () => {
    try {
      const postIDs = await fetch(
        `https://graph.instagram.com/me/media?fields=id,caption&access_token=${process.env.REACT_APP_META_ACCESS_TOKEN}`
      ).then((res) => res.json());

      let photosArray = [];

      // for each post Id get the media for the last 9 posts
      for (let i = 0; i < 9; i++) {
        const post = await fetch(
          `https://graph.instagram.com/${postIDs.data[i].id}?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.REACT_APP_META_ACCESS_TOKEN}`
        ).then((res) => res.json());

        photosArray.push({
          id: i,
          src: post.media_url,
          alt: post.caption,
        });
      }

      // const defaultPhotosArray = [
      //   {
      //     id: 0,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 1,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 2,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 3,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 4,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 5,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 6,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 7,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      //   {
      //     id: 8,
      //     src: DefaultImg,
      //     alt: "default 1",
      //   },
      // ];

      // Insert QR in the middle of the array (position 4)
      photosArray.splice(4, 0, {
        id: 9,
        src: QRCode,
        alt: "Instagram QR Code",
      });
      setPhotos(photosArray);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstagramPictures();
  }, []);

  return (
    <motion.section
      id="instagram"
      className="instagram container d-flex flex-column justify-content-between align-items-center py-5 gap-3"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h4 className="text-center">{t("instagram1")}</h4>
        <h1 className="text-center">{t("instagram2")}</h1>
        <a
          href="https://instagram.com/padel.alley"
          className="py-2 px-3 fw-bold ig-btn d-flex gap-1 align-items-center my-4"
        >
          <i className="bi bi-instagram" />
          @padel.alley
        </a>
      </div>
      {loading ? (
        <div className="loading-container d-flex justify-content-center align-items-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* For larger screens */}
          <div className="d-none d-md-grid grid-template-columns gap-3">
            {photos.slice(0, 9).map((photo, index) => (
              <img
                key={photo.id}
                className="ig-img img-fluid"
                src={photo.src}
                alt={"Instagram" + index}
                loading="lazy"
              />
            ))}
          </div>
          {/* For smaller screens */}
          <div className="d-md-none w-100 mb-4">
            <Slider {...sliderSettings}>
              {photos.map((photo, index) => (
                <img
                  key={photo.id}
                  className="ig-img img-fluid"
                  src={photo.src}
                  alt={"Instagram" + index * 2}
                  loading="lazy"
                />
              ))}
            </Slider>
          </div>
        </>
      )}
      <button
        className="btn"
        type="button"
        onClick={(e) =>
          (window.location.href = "https://instagram.com/padel.alley")
        }
        aria-label="Go to Instagram"
      >
        {t("followUs")}
      </button>
    </motion.section>
  );
}
