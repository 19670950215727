import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

// Map
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

// Images
import PadelAlley from "../images/padelAlley.webp";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const center = {
    lat: 32.236248,
    lng: -110.865553,
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const mapContainerStyle = {
    width: "100%",
    maxWidth: "60rem",
    height: "30rem",
    borderRadius: "5px",
  };

  const mapOptions = {
    disableDefaultUI: true, // Hides all HUD items like zoom controls and map type selector
    zoomControl: false, // Adds back only the zoom control
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }], // Hides points of interest
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }], // Custom road color
      },
      {
        featureType: "water",
        stylers: [{ color: "#bde0ff" }], // Custom water color
      },
    ],
  };
  const currentYear = new Date().getFullYear();

  const contactItems = [
    {
      id: 0,
      icon: "bi bi-phone",
      content: "(520) 314 9100",
      href: "tel:5203149100",
    },
    {
      id: 1,
      icon: "bi bi-map",
      content: "5943 E Speedway Blvd, Tucson, AZ 85712, United States",
      href: "https://maps.app.goo.gl/5suX8JTMPiAn2S4K7",
    },
    {
      id: 2,
      icon: "bi bi-envelope",
      content: "hello@padelalley.com",
      href: "mailto:hello@padelalley.com",
    },
  ];

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <motion.footer
      id="footer"
      className="footer-bg"
      aria-labelledby="footer-heading"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="container d-flex flex-column justify-content-between align-items-center py-5 gap-5">
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <h4 id="footer-heading" className="text-center text-white">
            {t("footer1")}
          </h4>
          <h1 className="text-center text-white">{t("footer2")}</h1>
        </div>
        <div className="w-100 d-flex flex-column-reverse flex-md-row justify-content-between align-items-center gap-5">
          <div className="d-flex flex-column justify-content-between gap-5">
            {contactItems.map((contact) => (
              <a
                key={contact.id}
                className="d-flex flex-column justify-content-center align-items-center align-items-md-start gap-1 text-decoration-none"
                href={contact.href}
                aria-label={contact.content}
              >
                <i className={`contact-icon ${contact.icon}`} />
                <p className="text-bold text-center text-md-start text-white m-0 text-wrap">
                  {contact.content}
                </p>
              </a>
            ))}
            <div className="d-flex flex-column justify-content-center align-items-center align-items-md-start gap-1">
              <i className="contact-icon bi bi-clock" />
              <div>
                <p className="text-bold text-center text-md-start text-white m-0 text-nowrap">
                  {t("time1")}: 7 AM - 12 AM
                </p>
              </div>
            </div>
          </div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={16}
            center={center}
            options={mapOptions}
            aria-label="Location of Padel Alley"
          >
            <MarkerF position={{ lat: 32.236248, lng: -110.865553 }} />
          </GoogleMap>
        </div>
        <div className="w-100 d-flex flex-column-reverse flex-md-row justify-content-between align-items-center gap-5 mt-5">
          <a
            className="navbar-brand-mobile"
            href="/"
            aria-label="Padel Alley Home"
          >
            <img
              className="img-fluid logo"
              src={PadelAlley}
              alt="Padel Alley logo"
              loading="lazy"
            />
          </a>
          <p className="text-center text-white">
            &copy; {currentYear} | Padel Alley | {t("copyright")}
          </p>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <a
              href="https://instagram.com/padel.alley"
              aria-label="Follow Padel Alley on Instagram"
            >
              <i className="contact-icon bi bi-instagram" />
            </a>
            <a
              href="https://www.facebook.com/share/1EWdg4Zxqy/?mibextid=LQQJ4d"
              aria-label="Follow Padel Alley on Facebook"
            >
              <i className="contact-icon bi bi-facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/padel-alley/"
              aria-label="Follow Padel Alley on LinkedIn"
            >
              <i className="contact-icon bi bi-linkedin" />
            </a>
            <button
              className="contact-icon"
              onClick={() => {
                i18n.changeLanguage(i18n.language === "en" ? "es" : "en");
              }}
              aria-label="Toggle language"
            >
              {i18n.language === "en" ? "ES" : "EN"}
            </button>
            <a
              href="https://wa.me/"
              className="whatsapp-button"
              target="_blank"
              rel="noreferrer"
              aria-label="Chat with us on WhatsApp"
            >
              <i className="bi bi-whatsapp" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </motion.footer>
  );
}
