import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import PA101 from "../images/PA-101.webp";

export default function Tutorial() {
  const { t } = useTranslation();
  return (
    <motion.section
      id="tutorial"
      className="tutorial-bg"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <img
        src={PA101}
        alt="Padel 101 Logo"
        className="img-fluid img-101 d-none d-md-block"
      />
      <div className="container d-flex flex-column justify-content-between align-items-center py-5 gap-3">
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <h4 className="text-center">{t("tutorial1")}</h4>
          <h1 className="text-center">{t("tutorial2")}</h1>
        </div>
        <p className="text-center">{t("tutorialDescription")}</p>
        <div className="video-container">
          <iframe
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
            src="https://www.youtube.com/embed/rSIvS-Skg28?si=UL6noFci4BO8JOBN"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </motion.section>
  );
}
