import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import PadelAlley from "../images/padelAlley.webp";

import { scrollToSection } from "../helpers";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 400px -10px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

function MobileNavBar({ t, items, isModalOpen, handleToggleModal, modalRef }) {
  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      <div className="modal-backdrop" aria-hidden="true"></div>

      <motion.nav
        animate={isModalOpen ? "open" : "closed"}
        variants={sidebar}
        className="d-flex side-modal flex-column justify-content-between align-items-start p-3"
        ref={modalRef}
        aria-label="Mobile Navigation Menu"
      >
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            {/* Mobile Logo */}
            <a
              className="navbar-brand-mobile"
              href="/"
              aria-label="Padel Alley Home"
            >
              <img
                className="img-fluid logo"
                src={PadelAlley}
                alt="Padel Alley logo"
                loading="lazy"
              />
            </a>
            {/* Close button for the modal */}
            <motion.button
              className="close-button navbar-toggler p-2 bg-primary"
              aria-label="Close navigation menu"
              onClick={handleToggleModal}
            >
              <i className="bi bi-x-lg" aria-hidden="true"></i>
            </motion.button>
          </div>

          {/* Mobile Navigation Links */}
          <ul className="w-100 navbar-nav d-flex flex-column align-items-start mt-5">
            {items.map((item) => (
              <li key={item.id} className="w-100">
                <button
                  className="w-100 nav-link text-nowrap d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={(e) => {
                    scrollToSection(item.url);
                    handleToggleModal();
                  }}
                  aria-label={`Go to ${item.name} section`}
                >
                  {item.name}
                  <i className="bi bi-arrow-right" aria-hidden="true"></i>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-100 d-flex justify-content-between mt-5">
          <button
            className="btn"
            type="button"
            onClick={(e) => scrollToSection("contact")}
            aria-label="Go to Contact Us section"
          >
            {t("reserveNow")}
          </button>
          <a
            href="https://wa.me/"
            className="whatsapp-button"
            target="_blank"
            rel="noreferrer"
            aria-label="Chat with us on WhatsApp"
          >
            <i className="bi bi-whatsapp" aria-hidden="true" />
          </a>
        </div>
      </motion.nav>
    </>
  );
}

export default function Navbar() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();

  const NavBarItems = [
    { id: 0, name: t("about"), url: "about" },
    {
      id: 1,
      name: t("academy"),
      url: "academy",
    },
    {
      id: 2,
      name: t("openPlay"),
      url: "openPlay",
    },
    {
      id: 3,
      name: t("calendarAndEvents"),
      url: "calendar",
    },
    {
      id: 4,
      name: t("newToPadel"),
      url: "tutorial",
    },
    {
      id: 5,
      name: t("contactUs"),
      url: "footer",
    },
  ];

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "unset";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <nav className="navbar navbar-expand-xl bg-transparent py-md-4">
      <div className="container">
        <a className="navbar-brand" href="/" aria-label="Padel Alley Home">
          <img
            className="img-fluid logo"
            src={PadelAlley}
            alt="Padel Alley logo"
            loading="lazy"
          />
        </a>

        {/* Toggle button for small screens */}
        <motion.button
          className="navbar-toggler p-2 bg-primary"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isModalOpen}
          aria-label={
            isModalOpen ? "Close navigation menu" : "Open navigation menu"
          }
          onClick={handleToggleModal}
        >
          <i className="bi bi-list hamburgerIcon" aria-hidden="true"></i>
        </motion.button>

        {/* Mobile Navigation */}
        <MobileNavBar
          t={t}
          items={NavBarItems}
          isModalOpen={isModalOpen}
          handleToggleModal={handleToggleModal}
          modalRef={modalRef}
        />

        {/* Desktop Navigation */}
        <div className="collapse navbar-collapse">
          <div
            className="w-100 d-flex justify-content-between align-items-center"
            id="navbarNav"
          >
            <ul className="w-100 navbar-nav gap-3 d-flex justify-content-center align-items-center">
              {NavBarItems.map((item) => (
                <li key={item.id}>
                  <button
                    className="nav-link text-nowrap"
                    type="button"
                    onClick={() => {
                      scrollToSection(item.url);
                    }}
                    aria-label={`Go to ${item.name} section`}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="d-flex justify-content-end align-items-center gap-3">
              <button
                className="btn"
                type="button"
                onClick={(e) => scrollToSection("contact")}
                aria-label="Go to Contact Us section"
              >
                {t("reserveNow")}
              </button>
              <button
                className="btn d-flex gap-2 bg-green"
                type="button"
                onClick={() => {
                  window.open("https://wa.me/", "_blank");
                }}
                aria-label="Chat with us on WhatsApp"
                title="Chat with us on WhatsApp"
              >
                <i className="bi bi-whatsapp" aria-hidden="true" />
                {t("joinOurCommunity")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
