import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import PA101 from "../images/PA-101.webp";

export default function Academy() {
  const { t } = useTranslation();
  return (
    <motion.section
      id="academy"
      className="academy-bg py-5 px-3 px-md-5 d-flex justify-content-center align-items-center"
      aria-labelledby="academy-heading"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <img src={PA101} alt="Padel 101 Logo" className="img-fluid img-101 d-none d-md-block"/>
      <div className="container bg-white py-5 px-md-5 d-flex flex-column justify-content-center align-items-center gap-3 z-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4 className="text-center">{t("academy2")}</h4>
          <h1 id="academy-heading" className="text-center">
            {t("academy1")}
          </h1>
        </div>

        <p className="description text-center">{t("academyDescription")}</p>
      </div>
    </motion.section>
  );
}
