// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            about: "About",
            academy: "Academy",
            newToPadel: "New to Padel",
            openPlay: "Open Play",
            calendarAndEvents: "Calendar & Events",
            contactUs: "Contact Us",
            reserveNow: "Reserve Now",
            hero1: "Get ready for the ultimate",
            hero2: "sports and fun",
            hero3: "destination!",
            about1: "Welcome to",
            aboutDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac tellus congue ligula vestibulum maximus nec sit amet leo. Fusce congue elementum lorem in tristique. Aliquam non nisl in augue congue faucibus eu non urna. Aenean posuere ullamcorper purus in scelerisque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam at erat eget nibh feugiat vehicula.",
            joinOurCommunity: "Join our community",
            amenities1: "Enjoy our courts and",
            amenities2: "amenities",
            amenitiesDescription: "Join us and be part of the fastest-growing sport in the world—where fun, fitness, and friendship come together. Whether you’re a seasoned player or new to the sport, we’re here to make it a great experience. Let’s play!",
            amenitiesItem1: "8 Padel Courts",
            amenitiesItem2: "4 Pickleball Courts",
            amenitiesItem3: "Terrace Bar",
            amenitiesItem4: "Café",
            amenitiesItem5: "Showers",
            amenitiesItem6: "Pro Shop",
            amenitiesItem7: "Lockers",
            instagram1: "Follow us on",
            instagram2: "Instagram",
            followUs: "Check us out!",
            academy1: "Academy",
            academy2: "Coaching and Classes",
            academyDescription: "We are happy to provide one on one classes to improve your game. We offer group clinics where several players can receive coaching in a dynamic exercises. We will also be providing “technique sessions” where we will focusing to master specific movements and shots. If you are interested in any of these classes please let us know or select in playtomic the one of your choice.",
            coachingInfo: "Coaching Info",
            icons1: "Meet our",
            icons2: "Icons",
            iconsDescription: "The Padel Alley Icons are ambassadors that love Padel and help us grow the game of Padel.",
            openPlay1: "Learn more about",
            openPlay2: "Open Play",
            openPlayDescription: "Open Play refers to a casual, recreational game of padel where players of similar skill levels can simply show up and play matches against each other on a padel court, typically in an “americano format” (all the teams compete against each other), mexicano format (where you have a different teammate every game) or round-robin format. The idea is to allow everyone and anyone to join in and enjoy the sport without needing to pre-arrange partners or matches; essentially, its an open opportunity to play padel with other players at your skill level without any formal competition structure, the only requirement is that you sign before the game!",
            tutorial1: "New to",
            tutorial2: "Padel?",
            tutorialDescription: "Hi there, happy to see you here. Welcome to Padel Alley! Padel is a great dynamic sport, easy to play and hard to master. Please come visit us. We have an Intro to Padel 30 minute session which we have scheduled every day, it includes coach and free rental racket. We are waiting for you. Please watch the following video for a great intro to Padel.",
            calendar1: "Check our",
            calendar2: "Calendar",
            calendarDescription: "Please check our complete calendar of Tournaments and Events as follows:",
            calendarDescription2: "Host your event with us!, reach out to us here.",
            membership1: "Become a",
            membership2: "Member",
            footer1: "Have any questions?",
            footer2: "Get in touch",
            time1: "Monday - Sunday",
            copyright: "All rights reserved",
        }
    },
    es: {
        translation: {
            about: "Nosotros",
            academy: "Academia",
            openPlay: "Open Play",
            newToPadel: "Nuevo en Pádel",
            calendarAndEvents: "Calendario y Eventos",
            contactUs: "Contáctanos",
            reserveNow: "Reserva Ahora",
            hero1: "¡Prepárate para lo último en",
            hero2: "deportes y diversión!",
            hero3: "",
            about1: "Bienvenido a",
            aboutDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac tellus congue ligula vestibulum maximus nec sit amet leo. Fusce congue elementum lorem in tristique. Aliquam non nisl in augue congue faucibus eu non urna. Aenean posuere ullamcorper purus in scelerisque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam at erat eget nibh feugiat vehicula.",
            joinOurCommunity: "Únete a nuestra comunidad",
            amenities1: "Disfruta nuestras canchas y",
            amenities2: "amenidades",
            amenitiesDescription: "Únete a nosotros y sé parte del deporte de más rápido crecimiento en el mundo, donde la diversión, el fitness y la amistad se unen. Ya seas un jugador experimentado o nuevo en el deporte, estamos aquí para hacer que sea una gran experiencia. ¡Vamos a jugar!",
            amenitiesItem1: "8 Canchas de Pádel",
            amenitiesItem2: "4 Canchas de Pickleball",
            amenitiesItem3: "Terraza Bar",
            amenitiesItem4: "Café",
            amenitiesItem5: "Regaderas",
            amenitiesItem6: "Tienda Pro",
            amenitiesItem7: "Lockers",
            instagram1: "Síguenos en",
            instagram2: "Instagram",
            followUs: "¡Síguenos!",
            academy1: "Academia",
            academy2: "Entrenamiento y Clases",
            academyDescription: "Estamos felices de proporcionar clases individuales para mejorar tu juego. Ofrecemos clínicas grupales donde varios jugadores pueden recibir entrenamiento en ejercicios dinámicos. También ofreceremos “sesiones de técnica” donde nos enfocaremos en dominar movimientos y tiros específicos. Si estás interesado en alguna de estas clases, háznoslo saber o selecciona en playtomic la que prefieras.",
            coachingInfo: "Información de Entrenadores",
            icons1: "Conoce a nuestros",
            icons2: "Íconos",
            iconsDescription: "Los Íconos de Pádel Alley son embajadores que aman el Pádel y nos ayudan a hacer crecer el deporte del Pádel.",
            openPlay1: "Aprende más sobre",
            openPlay2: "Open Play",
            openPlayDescription: "Open Play se refiere a un juego casual y recreativo de pádel donde los jugadores de niveles de habilidad similares pueden simplemente presentarse y jugar partidos entre sí en una cancha de pádel, típicamente en un formato “americano” (todos los equipos compiten entre sí), formato mexicano (donde tienes un compañero de equipo diferente en cada juego) o formato de round-robin. La idea es permitir que todos y cualquiera se unan y disfruten del deporte sin necesidad de pre-organizar parejas o partidos; esencialmente, es una oportunidad abierta para jugar pádel con otros jugadores de tu nivel de habilidad sin ninguna estructura de competencia formal, ¡el único requisito es que te registres antes del juego!",
            tutorial1: "Nuevo en",
            tutorial2: "Pádel?",
            tutorialDescription: "Hola, ¡felíz de verte aquí! Bienvenido a Pádel Alley! Pádel es un gran deporte dinámico, fácil de jugar y difícil de dominar. Por favor ven a visitarnos. Tenemos una sesión de Introducción al Pádel de 30 minutos que hemos programado todos los días, incluye entrenador y alquiler de raqueta gratis. Te estamos esperando. Por favor mira el siguiente video para una gran introducción al Pádel.",
            calendar1: "Revisa nuestro",
            calendar2: "Calendario",
            calendarDescription: "Por favor revisa nuestro calendario completo de Torneos y Eventos como sigue:",
            calendarDescription2: "¡Organiza tu evento con nosotros!, contáctanos aquí.",
            membership1: "Conviértete en",
            membership2: "Miembro",
            footer1: "¿Tienes alguna pregunta?",
            footer2: "Contáctanos",
            time1: "Lunes - Domingo",
            copyright: "Todos los derechos reservados",
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // default language
        fallbackLng: "en", // language to use if the current one is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;