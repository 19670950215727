import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

export default function Icons() {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fetchInstagramPictures = async () => {
    try {
      const postIDs = await fetch(
        `https://graph.instagram.com/me/media?fields=id,caption&access_token=${process.env.REACT_APP_META_ACCESS_TOKEN_ICONS}`
      ).then((res) => res.json());

      let photosArray = [];

      // for each post Id get the media for the last 9 posts
      for (let i = 0; i < 4; i++) {
        const post = await fetch(
          `https://graph.instagram.com/${postIDs.data[i].id}?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.REACT_APP_META_ACCESS_TOKEN_ICONS}`
        ).then((res) => res.json());

        photosArray.push({
          id: i,
          src: post.media_url,
          alt: post.caption,
        });
      }

      // const icons = [
      //   {
      //     id: 0,
      //     src: DefaultImg,
      //     alt: "default 1",
      //     name: "John Doe",
      //     position: "Certified Coach",
      //   },
      //   {
      //     id: 1,
      //     src: DefaultImg,
      //     alt: "default 1",
      //     name: "John Doe",
      //     position: "Certified Coach",
      //   },
      //   {
      //     id: 2,
      //     src: DefaultImg,
      //     alt: "default 1",
      //     name: "John Doe",
      //     position: "Certified Coach",
      //   },
      //   {
      //     id: 3,
      //     src: DefaultImg,
      //     alt: "default 1",
      //     name: "John Doe",
      //     position: "Certified Coach",
      //   },
      // ];

      setPhotos(photosArray);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstagramPictures();
  }, []);

  return (
    <motion.section
      id="icons"
      className="icons container d-flex flex-column justify-content-between align-items-center py-5 gap-3"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center mb-3">
        <h4 className="text-center">{t("icons1")}</h4>
        <h1 className="text-center">{t("icons2")}</h1>
      </div>
      <p className="description text-center">{t("iconsDescription")}</p>
      {loading ? (
        <div className="loading-container d-flex justify-content-center align-items-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* <div className="d-none d-md-flex flex-column flex-md-row justify-content-between align-items-center gap-3 mb-md-3">
            {photos.map((icon) => (
              <IconCard key={icon.id} src={icon.src} alt={icon.alt} />
            ))}
          </div> */}
          <div className="w-100 mb-4">
            <Slider {...sliderSettings}>
              {photos.map((icon, index) => (
                <img
                  key={icon.id}
                  className="d-flex flex-column justify-content-center align-items-centericon-img img-fluid px-1"
                  src={icon.src}
                  alt={"Instagram" + index}
                  loading="lazy"
                />
              ))}
            </Slider>
          </div>
        </>
      )}
    </motion.section>
  );
}
