import React from 'react';

// Sections
import Navbar from './sections/Navbar';
import Hero from './sections/Hero';
import Amenities from './sections/Amenities';
import Instagram from './sections/Instagram';
import Academy from './sections/Academy';
import Icons from './sections/Icons';
import OpenPlay from './sections/OpenPlay';
import Tutorial from './sections/Tutorial';
import Calendar from './sections/Calendar';
import Footer from './sections/Footer';
import ComingSoon from './sections/ComingSoon';

import logoWatermark from "./images/logoWatermark2.svg";

export default function App() {
  const isComingSoon = false;

  if (isComingSoon) {
    return <ComingSoon />;
  }

  return (
    <main className="App">
      <Navbar />
      <div className='top-bg bg-opacity-25'>
        <div className="overlay"></div>
        <Hero />
      </div>
      <div className='amenities-bg'>
        <Amenities />
        <img src={logoWatermark} alt="Tennis Ball Watermark" className="watermark img-fluid" />
      </div>
      <Instagram />
      <Academy />
      <Icons />
      <OpenPlay />
      <Tutorial />
      <Calendar />
      <Footer />
    </main>
  );
}
